import React, { ReactNode } from "react";
import { Layout } from "antd";
import { useNavigate } from "react-router-dom";
import cookie from "react-cookies";

import styled from "@emotion/styled";
import logoIcon from '../assets/logo/logo.png'
import logo_mt from "../assets/logo/mt-logo.png"
import logo_mcc from "../assets/logo/MCConline-logo.png"
import logo_Line from "../assets/logo/line.png"
import styles from "./layout.less";
import RightContent from "../components/RightContent";
import Menus from "../components/menu/Menus";
import MyFooter from "../components/footer/footer";

const { Header, Content, Footer } = Layout;

const PrimaryLayout = ({
    children,
}: {
    children: React.ReactElement;
}): React.ReactElement => {

    const navigate = useNavigate();

    const BackHome = () => {
        navigate({
            pathname: '/',
        })
    }

    const BackHomes = () => {
        window.location.href = "https://www.mthreads.com/"
    }

    return (
        <Layout className={styles.layout}>
            <Header className={styles.headerBar}>
                    <LogoImg src={logo_mt} onClick={BackHomes} />
                    <LogoImgLine src={logo_Line}/>
                    <LogoImgRight src={logo_mcc} onClick={BackHome} />
                <Menus />
                <RightContent />
            </Header>
            <Content
                style={{ minHeight: "calc(100vh - 60px)", paddingBottom:170,background: "#F2F2F2",zIndex: 10 }}
            >
                {children}
            </Content>
            <MyFooter/>
        </Layout>
    );
};

export default PrimaryLayout;

const LogoImg = styled.img`
  width: 93px;
  height: 28px;
  cursor: pointer;
  margin: 0;
`
const LogoImgLine = styled.img`
  margin: 0 5px;
  width: 2px;
  height: 20px;

`
const LogoImgRight = styled.img`
  width: 165px;
  height: 17px;
  cursor: pointer;
`
