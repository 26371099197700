import React from "react";

const MainPage = React.lazy(() => import("../pages/mainpage"));
const ImageInfo = React.lazy(() => import("../pages/image-info"));
const TagInfo = React.lazy(() => import("../pages/tag-info"));
const RepoList = React.lazy(()=> import('../pages/repolist'))
const SoftwareList = React.lazy(()=> import('../pages/software'))
const ReleaseNote = React.lazy(()=> import('../pages/release-note'))
const SoftwareInfo = React.lazy(()=>import('../pages/software-info'))
const HelmChartList = React.lazy(()=> import('../pages/helm-chart'))
const HelmChartInfo = React.lazy(()=> import('../pages/helm-chart-info'))

export {
    MainPage,
    ImageInfo,
    TagInfo,
    RepoList,
    SoftwareList,
    ReleaseNote,
    SoftwareInfo,
    HelmChartList,
    HelmChartInfo
};
