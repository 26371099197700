import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";

import { Dropdown, Menu } from "antd";
import { Link } from "react-router-dom";
import styles from "./menu.less";

const Menus = () => {

  let showBadge = false;
  let isLogin = true;
  const [activeKey, setActiveKey] = useState("Homepage");
  const handleClick = (e: any) => {
    setActiveKey(e.key);
  };
  useEffect(() => {
    const relPath = getUrlRelativePath();
    menuList.forEach((item) => {
      if (relPath.indexOf(item.link.toLowerCase()) !== -1){
        setActiveKey(item.key);
      }
    }
    );
  });

  const menuList = [
    {
      key: "Homepage",
      link: "/",
      name: '首页',
    },
    {
      key: "repo",
      link: "/repo",
      name: '镜像仓库',
    },
    {
      key: "helmchart",
      link: "/helmchart",
      name: 'Helm Chart',
    },
    {
      key: "software",
      link: "/software",
      name: '软件资源',
    },
  ];

  return (
    <HeaderMenu>
      <Menu
        mode="horizontal"
        style={{
          color: "#ffffff",
        }}
        className={styles.menuBar}
        selectedKeys={[activeKey]}
        onClick={(e) => handleClick(e)}
      >
        {menuList.map((item) => (
          <Menu.Item key={item.key}>
            <Link to={item.link}>{item.name}</Link>
          </Menu.Item>
        ))}
      </Menu>
    </HeaderMenu>
  );
};

export default Menus;

const getUrlRelativePath = () => {
  const url = document.location.toString();
  const arrUrl = url.split("//");

  const start = arrUrl[1].indexOf("/");
  let relUrl = arrUrl[1].substring(start);

  if (relUrl.indexOf("?") !== -1) {
    const urlList: Array<string> = relUrl.split("?");
    // eslint-disable-next-line prefer-destructuring
    relUrl = urlList[0];
  }
  return relUrl;
};

const coverString = (subStr: string, str: string) => {
  // const reg = `/${subStr}/ig`;
  const lowerStr = subStr.toLowerCase();
  const reg = new RegExp(`/${lowerStr}/ig`);
  return reg.exec(str);
};

const HeaderMenu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
