import React from "react";
import styled from "@emotion/styled";
import { Divider } from "antd";
import logoIcon from "../../assets/logo/footer_logo.png";

const VerticalDivider = () => (
  <Divider
    style={{ background: "rgba(255,255,255,0.3)", marginTop: 5 }}
    type="vertical"
  />
);

const MyFooter = (): React.ReactElement => {
  const officialWebsiteUrl = "https://www.mthreads.com";
  const legalUrl = `${officialWebsiteUrl}/legal`;
  const privacyUrl = `${officialWebsiteUrl}/privacy`;
  return (
    <FooterFrame>
      <InnerFrame style={{ width: 1020 }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ flex: 1, paddingTop: 50 }}>
            <img width={128} src={logoIcon} alt={'摩尔线程'} />
          </div>

          <ContactDiv style={{ alignItems: "end" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 14,
              }}
            >
              <Contact href={officialWebsiteUrl} target="_blank">
                摩尔线程官网
              </Contact>
              <VerticalDivider />
              <Contact href={legalUrl} target="_blank">
                法律声明
              </Contact>
              <VerticalDivider />
              <Contact href={privacyUrl} target="_blank">
                隐私声明
              </Contact>
            </div>
            <DescText style={{ flex: 1 }}>
              <DescText style={{ color: "#c3c3c3" }}>
                Copyright © 2020-{new Date().getFullYear()} 摩尔线程版权所有
                <a
                  style={{ marginLeft: 5, color: "#c3c3c3" }}
                  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802035174"
                  target="_blank"
                  rel="noreferrer"
                >
                  京公网安备 11010802035174号
                </a>
                <a
                  style={{ marginLeft: 5, color: "#c3c3c3" }}
                  href="https://beian.miit.gov.cn/#/Integrated/index"
                  target="_blank"
                  rel="noreferrer"
                >
                  京ICP备2020041674号-2
                </a>
              </DescText>
            </DescText>
          </ContactDiv>
        </div>
      </InnerFrame>
    </FooterFrame>
  );
};

export default MyFooter;

const FooterFrame = styled.div`
  padding: 55px 0;
  width: 100%;
  background: #000000;
  height: 260px;
  display: flex;
  justify-content: center;
`;

const InnerFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
`;
const Contact = styled.a`
  font-size: 16px;
  font-weight: 400;
  color: #c3c3c3;
`;

const DescText = styled.span`
  font-size: 16px;
  font-weight: 400;
  text-align: right;
`;

const ContactDiv = styled.span`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;
