import React from "react";
import styled from "@emotion/styled";
import LoginBg from '../assets/bg/login_bg.png'
import logo_mt from "../assets/logo/mt-logo.png"
import logo_mcc from "../assets/logo/MCConline-logo.png"
import logo_Line from "assets/logo/line.png"

const LoginLayout = ({
                         children,
                     }: {
    children: React.ReactElement;
}): React.ReactElement => {

    return (
        <Frame>
            <TopTit>
                <TopImg>
                    <LogoImg src={logo_mt}/>
                    <LogoImgLine src={logo_Line}/>
                    <LogoImgRight src={logo_mcc}/>
                </TopImg>
            </TopTit>
            {children}

        </Frame>
    );
};

export default LoginLayout;

const Frame = styled.div`
  width: 100%;
  min-height: 100vh;
  background: url(${LoginBg}) no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 80px;
  box-sizing: border-box;
`
const LogoImg = styled.img`
  width: 93px;
  height: 28px;
  cursor: pointer;
  margin: 0;
`
const LogoImgLine = styled.img`
  margin: 0 5px;
  width: 2px;
  height: 20px;

`
const LogoImgRight = styled.img`
  width: 165px;
  height: 17px;
  cursor: pointer;
`

const TopTit = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  padding: 20px;
  box-sizing: border-box;
  background: #f8f8f8;
`;

const TopImg = styled.div`
  height: 100%;
  width: auto;
  display: flex;
  justify-content: justify-start;
  align-items: center;
`;
const ImgBox = styled.img`
  height: 100%;
  display: block;
`;
