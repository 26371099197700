import React, { useState } from "react";
import styled from "@emotion/styled";
import { Spin, Space } from "antd";

const Fallback = () => (
  <Spin size="large">
    <Frame />
  </Spin>
);

const Frame = styled.div`
  width: 100vw;
  height: 100vh;
`;

export default Fallback;
