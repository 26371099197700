import React from 'react';
import './App.less';
import MyRoutes from "./router/MyRoutes";


function App() {
  return (
      <MyRoutes/>
  );
}

export default App;