import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { LogoutOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import styled from "@emotion/styled";
import { AuthenticationClient } from 'authing-js-sdk';
import avatarIcon from 'assets/avatar.svg'

const authenticationClient = new AuthenticationClient({
    appId: process.env.REACT_APP_AUTH_ID || '654ccafdcbf4fc6591627479',
    secret: process.env.REACT_APP_AUTH_SECRET,
    appHost: process.env.REACT_APP_AUTH_HOST,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
});

const RightContent = (): React.ReactElement => {
    const [userInfo, setUserInfo] = useState<any>({})
    const [avatar,setAvatar] = useState(avatarIcon)

    const items: MenuProps['items'] = [
        {
            label: '退出登录',
            icon: <LogoutOutlined />,
            key: 'logout'
        },
    ];
    const onClick: MenuProps['onClick'] = ({ key }) => {
        if (key === 'logout') {
            Logout()
        }
    };
    const Login = () => {
        const url = authenticationClient.buildAuthorizeUrl({
            scope: "openid profile username phone",
            responseType: 'id_token token'
        })
        Cookies.set('returnUrl', window.location.href);
        window.open(url)
    }

    const Logout = () => {
        Cookies.remove('access_token');
        Cookies.remove('id_token');
        const url = authenticationClient.buildLogoutUrl({
            redirectUri: process.env.REACT_APP_REDIRECT_URI,
        })
        Cookies.remove('returnUrl');
        window.open(url, "_self")
    }


    const getUser = async () => {
        const result = await authenticationClient.getUserInfoByAccessToken(Cookies.get('access_token') || '');
        if (!result.phone_number){
            setAvatar(result.picture)
        }
        setUserInfo(result)
    }
    const [isLogin, setIsLogin] = useState(!!Cookies.get('access_token'))

    useEffect(() => {
        if (isLogin) {
            getUser();
        }
    }, [])

    return (
        <Frame>
            {
                isLogin ?
                    <Dropdown menu={{ items, onClick }} >
                        <AvatarDiv>
                            <img src={avatar} width={30} height={30}
                                 style={{borderRadius: 15}}/>
                            <div>{userInfo.nickname || userInfo.username || userInfo.phone_number}</div>
                        </AvatarDiv>
                    </Dropdown>
                    :
                    <span onClick={Login}>登录/注册</span>
            }
        </Frame>
    )
}

export default RightContent

const Frame = styled.div`
  width: 120px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AvatarDiv = styled.div`
  display: flex;
  height: 60px;
  width: 120px;
  flex-direction: row;
  align-items: center;

  div {
    margin-left: 12px;
    width: 70px;
  }
`

function secondsToDays(seconds: any) {
    const secondsInADay = 24 * 60 * 60;
    return seconds / secondsInADay;
}

