import React, {Suspense, useEffect, useState} from "react";
import Cookies from 'js-cookie'
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import LoginLayout from "./LoginLayout";
import PrimaryLayout from "./PrimaryLayout";
import Fallback from "./Fallback";

export interface LayoutMapT {
    login: ({children}: { children: React.ReactElement; }) => React.ReactNode;
    primary: ({children}: { children: React.ReactElement; }) => React.ReactNode;
    [key: string]: ({children}: { children: React.ReactElement; }) => React.ReactNode;
}

const LayoutMap: LayoutMapT = {
    primary: PrimaryLayout,
    login: LoginLayout,
};

function secondsToDays(seconds: any) {
    const secondsInADay = 24 * 60 * 60;
    return seconds / secondsInADay;
}

const BaseLayout = () => {
    const saveUrlParamsToCookies = () => {
        const urlParams = new URLSearchParams(window.location.hash.replace('#', ''));
        const accessToken = urlParams.get('access_token') as string || '';
        if (accessToken && !Cookies.get('access_token')) {
            const expiresIn = parseInt(urlParams.get('expires_in') as string);
            const expires = secondsToDays(expiresIn)
            Cookies.set('access_token', accessToken, {
                expires: expires
            });
            Cookies.set('id_token', urlParams.get('id_token') as string, {
                expires: expires
            });
            let str:any = Cookies.get('returnUrl')
            if(str){
                window.location.replace(str || process.env.REACT_APP_REDIRECT_URI)
                Cookies.remove('returnUrl')
            }
        }else{
            window.location.hash = '';
        }
    }

    useEffect(() => {
        saveUrlParamsToCookies();
    }, [])
    const location = useLocation();
    const Container = LayoutMap[
        queryLayout(location.pathname)
        ] as React.ElementType;

    return (
        <Suspense fallback={<Fallback/>}>
            <Container>
                <Outlet/>
            </Container>
        </Suspense>
    );
};

export default BaseLayout;

const queryLayout = (pathname: string): string => {
    return 'primary';
};
