import React, {useEffect} from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import BaseLayout from "../layout/BaseLayout";

import {
    MainPage,
    ImageInfo,
    TagInfo,
    RepoList,
    SoftwareList,
    ReleaseNote,
    SoftwareInfo,
    HelmChartList,
    HelmChartInfo
} from "./desktopLazyload";

const MyRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<BaseLayout/>}>
                    <Route index element={<MainPage/>}/>
                    <Route path="repo" element={<RepoList/>}/>
                    <Route path="software" element={<SoftwareList/>}/>
                    <Route path="software/:driver/release-note" element={<ReleaseNote/>}/>
                    <Route path="repo/:image" element={<ImageInfo/>}/>
                    <Route path="software/:driver" element={<SoftwareInfo/>}/>
                    <Route path="repo/:image/:tag" element={<TagInfo/>}/>
                    <Route path="helmchart" element={<HelmChartList/>}/>
                    <Route path="helmchart/:chart" element={<HelmChartInfo/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default MyRoutes;
